import { useContext, useState } from 'react';
import { Col, Container, Nav, Row, Tab, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IArea } from "../../@types/project";
import { ProjectContext } from '../../context/projectContext';
import AreaFinanceTable from '../AreaFinanceTable/AreaFinanceTable';
import AreaGallery from '../AreaGallery/AreaGallery';
import InterestedCard from '../InterestedCard/InterestedCard';
import PanoViewer from '../PanoViewer/PanoViewer';
import './AreaCard.scss';
import useLogger from '../../hooks/useLogger';

type Props = {
    area?: IArea,
    handleClose: any
}

const AreaCard = ({area, handleClose} : Props) => {
    console.warn("AreaCard - area:",area);

    const context = useContext(ProjectContext);
    const logger = useLogger()
    const [show, setShow] = useState(true);
    const [showInterested, setShowInterested] = useState(false);
    const [showFinanceInfo, setShowFinanceInfo] = useState(false);
    const [activeTab, setActiveTab] = useState<any>("floor");
    const level = context?.project.buildings[0].levels.find((l : any) => l.level === context?.simulator.current_level)

    const mainImage = `projects/portiko/prototypes/prototype-${area?.prototype_code}.jpg`;
    const panoramaImages = context?.project.assets.views;
    const panoramaImageOrientation = panoramaImages.find((o: any) => o.prototypes.includes(area?.prototype_code) );
    const panoramaImage = `projects/portiko/views/${panoramaImageOrientation.name}/${panoramaImageOrientation.name}-${context?.simulator.current_level}.jpg`;

    const gallery = context?.project.assets.galleries.find((g:any) => g.prototypes.includes(area?.prototype_code));

    logger.log({
        action: 'VIEW',
        message: `Visitó la unidad: Nivel ${level?.level} - Area: ${area?.area}`,
    })

    const onHandleClose = () => {
        handleClose();
    };

    const handleInterestedArea = () => setShowInterested(true);
    const handleFinanceInfo = () => setShowFinanceInfo(true);
    const onHandleInterestedAreaClose = () => setShowInterested(false);
    const onHandleFinanceInfoClose = () => setShowFinanceInfo(false);

    return (
        <>
        <InterestedCard area={area} show={showInterested} handleClose={onHandleInterestedAreaClose} />
        <Modal
            className={`area-card ${showFinanceInfo ? 'show-finance':''}`}
            show={show}
            fullscreen={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHandleClose}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                <span className="home--logo" style={{ backgroundImage: `url(${ context?.project.styles.intro.logo })` }}></span>
            </Modal.Title>
            <Button className="btn-close btn-close-red" onClick={() => onHandleClose()}>Volver <span>X</span></Button>

          </Modal.Header>
          <Modal.Body className={`p-0 active-tab-${activeTab}`} style={{ backgroundColor: showFinanceInfo ? 'rgba(43, 28, 69, 1);': '' }}>
            <Tab.Container id="area-tabs" defaultActiveKey="floor" onSelect={key => setActiveTab(key) }>
                <aside className='area-card--aside'>
                    <Row>
                        <Col>
                            <div>
                                <span className='d-block text-primary area--area'>{ area?.area }</span>
                                <span className='d-block text-primary'>Nivel { level?.level }</span>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <span className='d-block area--status'>{ area?.dataAPI?.unidad?.estatus || 'Desconocido' } <span className={`status status--${ area?.dataAPI?.unidad?.estatus_id || 0 }`}></span></span>
                                <span className='d-block text-primary'>{area?.dataAPI?.vista?.nombre || ''}</span>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <div>
                        <h3 className="text-primary my-4 area--price">{ area?.dataAPI?.lista_base?.nombre ? `${area?.dataAPI?.lista_base?.nombre?.toLocaleString("en-US",{ style: 'currency', currency: 'USD' }) || 0}`: 'Precio no disponible'}</h3>
                    </div>
                    <div>
                        <ul style={{ listStyle: 'none' }} className='area--description ms-0 ps-0'>
                            {/* description.map((text : string, key : number) => <li key={key}><span className='icon d-inline-block me-3' style={{ width: '1.5em', height: '1em', border: '1px solid var(--bs-primary)' }}></span>{text}</li> )*/}
                            <li><span className="icon icon--size"></span><span><b>{ area?.dataAPI?.interior_m2?.nombre || '-' }</b> m<sup>2</sup></span></li>
                            <li><span className="icon icon--room"></span><span><b>{ area?.dataAPI?.recamaras?.nombre || '-' }</b> recámaras</span></li>
                            <li><span className="icon icon--bath"></span><span><b>{ area?.dataAPI?.banos?.nombre || '-' }</b> Baños</span></li>
                            <li><span className="icon icon--parking"></span><span><b>{ area?.dataAPI?.cajones?.nombre || '-' }</b> { area?.dataAPI?.cajones?.nombre == "1" ? 'Cajón' : 'Cajones' } de estacionamiento</span></li>
                        </ul>
                    </div>
                    <div className='area-card--tabs-nav text-center my-4'>
                        <Nav.Item className='d-inline-block'>
                            <Nav.Link eventKey="floor" className={`btn btn-text px-2 mx-1 my-1 text-primary ${activeTab=='floor' ? 'active' : ''}`}>Planta</Nav.Link>
                        </Nav.Item>
                        { gallery &&
                            <Nav.Item className='d-inline-block'>
                                <Nav.Link eventKey="gallery" className={`btn btn-text px-2 mx-1 my-1 text-primary ${activeTab=='gallery' ? 'active' : ''}`}>Galería</Nav.Link>
                            </Nav.Item>
                        }
                        <Nav.Item className='d-inline-block'>
                            <Nav.Link eventKey="view" className={`btn btn-text px-2 mx-1 my-1 text-primary ${activeTab=='view' ? 'active' : ''}`}>Vista</Nav.Link>
                        </Nav.Item>
                        {/*<Nav.Item className='d-inline-block'>
                            <Nav.Link eventKey="tour" className={`btn btn-text px-2 mx-1 my-1 text-primary ${activeTab=='tour' ? 'active' : ''}`}>Tour 360</Nav.Link>
                        </Nav.Item>*/}
                    </div>
                    <div className='text-center'>
                        {/*<Button variant='primary' className='text-white d-block mx-auto mb-4' onClick={handleInterestedArea}>Estoy interesado</Button>*/}
                        <Button variant='outline-primary' className='d-block mx-auto outline' onClick={handleFinanceInfo}>Ver Plan de pagos</Button>
                    </div>
                </aside>
                <main className={`area-card--main tab-${activeTab} ${showFinanceInfo ? 'show-finance': ''}`}>
                    { showFinanceInfo &&
                        <div className="area-card--content">
                            <Container fluid>
                                <Row>
                                    <Col className="px-5">
                                        <div className="text-end mb-4">
                                            <Button variant='secondary' onClick={onHandleFinanceInfoClose} className="btn-back"><small>&lsaquo; Cerrar planes <span></span></small></Button>
                                        </div>
                                        <AreaFinanceTable />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    }
                    { !showFinanceInfo &&
                        <Tab.Content>
                            <Tab.Pane eventKey="floor">
                                <div className="area-card--content">
                                    <div className='area-card--main-image'>
                                        <img src={mainImage} style={{ maxWidth: '100%' }} alt="" />
                                        <div className="area-card--notations">
                                            <div><span className="notation notation--voidExt"></span> Vacio Exterior</div>
                                            <div><span className="notation notation--voidInt"></span> Vacio Interior</div>
                                            <div><span className="notation notation--hall"></span> Pasillo Interior</div>
                                            <div><span className="notation notation--apt"></span> Departamento Inmediato</div>
                                            <div><span className="notation notation--window"></span> Ventana</div>
                                            <div><span className="notation notation--wall"></span> Muro</div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="gallery">
                                { activeTab=='gallery' && gallery &&
                                    <AreaGallery images={gallery.images} className='area-card--gallery' />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="view">
                                { activeTab=='view' &&
                                    <span className="area-card--view-full-bg" style={{backgroundImage: `url(${panoramaImage})`}}></span>
                                }
                                { /* activeTab=='view' && <PanoViewer image={panoramaImage}/> */}
                            </Tab.Pane>
                            <Tab.Pane eventKey="tour">
                                <div className="area-card--iframe">
                                    <iframe src="https://popinvestments.com/portiko/360/"></iframe>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>    
                    }
                </main>
            </Tab.Container>
          </Modal.Body>
        </Modal>
        </>
      );
}

export default AreaCard;